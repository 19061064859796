
import { defineComponent, ref, computed} from 'vue'
import Nav from '../../components/Nav/Nav.vue'
import PhoneBackBtn from '../../components/Nav/PhoneBackBtn.vue'
import Avatar from './Avatar.vue'
import ChangeUserInfo from './ChangeUserInfo.vue'
import LoginPhone from '../login/cpns/LoginPhone.vue'

import { BindWx, UnBind } from '../../service/login/login'

import { useStore } from '../../store'
import { useRouter } from 'vue-router'

import message from '../../utils/message'

export default defineComponent({
  name: 'user',
  components: {
    ChangeUserInfo,
    Nav,
    Avatar,
    LoginPhone,
    PhoneBackBtn
  },
  setup(props, content) {
    const store = useStore()
    const userInfo = computed(() => store.state.user.userInfo)
    const completeUserInfoDialogVisible = ref(false)
    const bindWxDialogVisible = ref(false)
    if (store.state.user.userInfo.firstLogin) {
      //用户首次登录从未补充信息
      completeUserInfoDialogVisible.value = true
    }
    const changeDialogVisible = () => {
      completeUserInfoDialogVisible.value = false
    }
    //绑定微信
    async function bindWx(state: string) {
      const data = await BindWx(state)
      if (data.success) {
        message.success('绑定成功')
        bindWxDialogVisible.value = false
        userInfo.value.userIdWechat = true
      } else {
        message.warning(data.data)
      }
    }
    async function unBindWx() {
      const data = await UnBind()
      if (data.success) {
        message.success('解绑成功')
        userInfo.value.userIdWechat = false
      } else {
        message.warning(data.data)
      }
    }
    const bindWxOk = () => {
      message.success('绑定成功')
      bindWxDialogVisible.value = false
      userInfo.value.userIdWechat = true
    }
    const changeBindWxDialogVisible = () => {
      bindWxDialogVisible.value = true
    }
    return {
      userInfo,
      completeUserInfoDialogVisible,
      bindWxDialogVisible,
      changeDialogVisible,
      changeBindWxDialogVisible,
      bindWxOk,
      unBindWx,
    }
  },
})
