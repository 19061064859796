
import { defineComponent, ref, reactive } from 'vue'
import localCache from '../../utils/cache'
import { useRouter } from 'vue-router'
import { pwdRules } from './config/pwdRule'

import message from '../../utils/message'

import type { FormInstance } from 'element-plus'
import { IEditPwd } from '../../service/user/type'

import { editPwd } from '../../service/user/user'

export default defineComponent({
  name: 'SetPwd',
  setup(props, content) {
    const router = useRouter()
    const ruleFormRef = ref<FormInstance>()
    const pwdForm = reactive({
      oldPwd: '',
      pwd: '',
    })

    //提交前验证表单
    const submitFormRule = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      await formEl.validate((valid: any, fields: any) => {
        if (valid) {
          const submitFormData = {
            oldPwd: pwdForm.oldPwd,
            pwd: pwdForm.pwd,
          }
          submitForm(submitFormData)
        }
      })
    }
    //提交表单
    async function submitForm(data: IEditPwd) {
      const value = await editPwd(data)
      if (value.success) {
        message.success('修改成功，请重新登录')
          localCache.deleteCache('token')
          router.replace('/login')
      }else{
          message.warning(value.data)
      }
    }
    return {
      pwdForm,
      submitFormRule,
      pwdRules,
      ruleFormRef,
    }
  },
})
