
import { defineComponent, ref, reactive } from 'vue'
import { useStore } from '../../store'
import { userRules } from './config/userRule'

import message from '../../utils/message'

import type { FormInstance } from 'element-plus'
import {IUserInfo} from '../../service/user/type'

import {checkPhone} from '../../utils/checkPhone'

import {editUserInfo} from '../../service/user/user'

export default defineComponent({
  name: 'ChangeUserInfo',
  emits:['changeDialogVisibleEmit'],
  setup(props, content) {
    const store = useStore()
    const ruleFormRef = ref<FormInstance>()
    const userInfoStore = store.state.user.userInfo
    const userInfoForm = reactive({...userInfoStore })

    //提交前验证表单
    const submitFormRule = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      await formEl.validate((valid:any, fields:any) => {
        if (valid) {
          const submitFormData= {
            userName: userInfoForm.userName,
            nickName: userInfoForm.nickName,
            phoneNum: checkPhone(userInfoForm.phoneNum),
            email: userInfoForm.email,
            sex: userInfoForm.sex,
          }
          submitForm(submitFormData)
        }
      })
    }
   
    //提交表单
    async function submitForm(data:IUserInfo) {
        const value = await editUserInfo(data)
        if(value.success){
          message.success('修改成功')
          //更改vuex里的用户信息
          store.commit('user/changeUserInfo',value.data)
          content.emit('changeDialogVisibleEmit')
        }else{
          message.error(value.data)
        }
    }
    return {
      userInfoForm,
      submitFormRule,
      userRules,
      ruleFormRef,
    }
  },
})
